@font-face {
	font-family: 'montserrat-bold';
	src: url('../fonts/montserrat-bold-webfont.woff2') format('woff2'),
		 url('../fonts/montserrat-bold-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'montserrat-regular';
	src: url('../fonts/montserrat-regular-webfont.woff2') format('woff2'),
		 url('../fonts/montserrat-regular-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}