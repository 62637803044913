.bg_white {
	background-color: #ffffff;
}
.bg_blue_rp {
	background: #00152F;
}
.bg_lightgrey_rp {
	background: #e9e9e9;
}
.bg_two_players_rp {
	background-image: url(../images/two_players_bg.jpg);
	background-size: cover;
	background-position: center;
}
.btn_black_rp {
	background: #000000;
	color: #ffffff;
}
.btn_black_rp:hover {
	background: #505050;
	color: #ffffff;
}
.btn_black_rp:focus {
	box-shadow: none;
}

.btn_transparent_rp {
background-color: #FFFFff40 !important;
border-color: #fff !important;
color: #fff !important;
}
  
.btn_transparent_rp:hover {
background-color: #FFFFff80 !important;
border-color: #fff !important;
}

.btn_transparent_rp:active,
.btn_transparent_rp:focus {
background-color: #FFFFff99 !important; 
border-color: #fff !important;
color: #fff !important;
outline: none !important;
}

.btn_red_rp {
	background: #DD004A;
	color: #ffffff;
}
.btn_red_rp:hover {
	background: #AE003A;
	color: #ffffff;
}
.btn_red_rp:focus {
	box-shadow: none;
}
.btn_red_outline_rp {
	background: none;
	border: solid 1px #DD004A;
	color: #ffffff;
}
.btn_red_outline_rp:hover {
	background: #AE003A;
	border: solid 1px #AE003A;
	color: #ffffff;
}
.btn_red_outline_rp:focus {
	box-shadow: none;
}
.btn_red_black_text {
	background: #DD004A;
	border: solid 1px #DD004A;
	color: #000000;
}
.btn_red_black_text:hover {
	background: #000000;
	border: solid 1px #AE003A;
	color: #AE003A;
}
.btn_red_black_text:focus {
	box-shadow: none;
}
.btn_blue_rp {
	background: #00D6D2;
	color: #ffffff;
}
.btn_blue_rp:hover {
	background: #00c1bd;
	color: #ffffff;
}
.btn_blue_standby_rp {
	background: #00494c;
	color: #ffffff;
}
.btn_blue_standby_rp:hover {
	opacity:0.8;
}
.lightblue_text {
	color: #C9CED4;
}
.hr_blue {
	border-top:solid 1px #00D6D2 !important;
}
@media (max-width: 767px) {
	.hr_blue {
		border-top: 1px solid #00d6d2!important;
		margin-right:5px;
		margin-left:-5px;
	}
}
.hr_white {
	border-top:solid 1px #ffffff !important;
}
.card_border_bottom_white {
	border-bottom: solid 1px #00D6D2;
}
.card_border_bottom_red {
	border-bottom: solid 1px #d60000;
}
.bold_title_rp {
	font-family: 'montserrat-bold';
}
.regular_title_rp {
	font-family: 'montserrat-regular' !important;
}
.cursor_default {
	cursor: default;
}
/* ease fx */
.ease_fx:link, .ease_fx:visited{
	-webkit-transition: color .25s linear, background-color .25s ease-in-out, border-color .25s ease-in-out, opacity .25s ease-in-out, box-shadow.25s ease-in-out;
	-moz-transition: color .25s linear, background-color .25s ease-in-out, border-color .25s ease-in-out, opacity .25s ease-in-out, box-shadow.25s ease-in-out;
	-o-transition: color .25s linear, background-color .25s ease-in-out, border-color .25s ease-in-out, opacity .25s ease-in-out, box-shadow.25s ease-in-out;
	transition: color .25s linear, background-color .25s ease-in-out, border-color .25s ease-in-out, opacity .25s ease-in-out, box-shadow.25s ease-in-out;
}

.ease_fx:hover{
	-webkit-transition: color .25s linear, background-color .25s ease-in-out, border-color .25s ease-in-out, opacity .25s ease-in-out, box-shadow.25s ease-in-out;
	-moz-transition: color .25s linear, background-color .25s ease-in-out, border-color .25s ease-in-out, opacity .25s ease-in-out, box-shadow.25s ease-in-out;
	-o-transition: color .25s linear, background-color .25s ease-in-out, border-color .25s ease-in-out, opacity .25s ease-in-out, box-shadow.25s ease-in-out;
	transition: color .25s linear, background-color .25s ease-in-out, border-color .25s ease-in-out, opacity .25s ease-in-out, box-shadow.25s ease-in-out;
}
.icon_h_20 {
	max-height: 20px;
	width: auto; 
}
.icon_h_40 {
	max-height: 40px;
	width: auto; 
}
.dropdown-menu {
	background-color: #00152F;
}
footer p {
	margin-bottom: 0;
	font-family: 'montserrat-regular';
	font-size: 0.8em;
}
@media (min-width: 575.98px) {
	.card-horizontal {
		display: flex;
		flex: 1 1 auto;
	}
}
.dashboard_hero_rp {
	background-image: url(../images/hero.jpg);
	transform: translate3d(0px, 80px, 0px);
	background-position: 50% 30%;
	height: 500px;
}
.dashboard_hero_overlay_rp {
	background: linear-gradient(45deg, rgba(20, 46, 125, 0.8) 0%, rgba(107, 26, 103, 0.8) 71%, rgba(221, 0, 74, 0.8) 100%);
	height: 100%;
	width: 100%;
}
.dashboard_hero_rp_sport_specialist {
	background-image: url(../images/hero_specialist.jpg);
	background-size: cover;
	background-position: 50% 30%;
	height: 500px;
	transform: translate3d(0px, 60px, 0px);
}
.main_raised_rp {
	margin-top: -360px;
}
.section_title_neg_rp {
	font-family: 'montserrat-bold';
	text-align: left;
	color: #ffffff;
	display: inline-block;
}
.section_title_pos_rp {
	font-family: 'montserrat-bold';
	text-align: left;
	color: #00152F;
}
.section_text_pos_rp {
	color: #00152F;
}
.video_card_bg_rp,
.favorites_card_bg_rp
.sportcenter_card_bg_rp {
	background: #00152F;
	color: #FFFFFF;
	text-align: left;
	box-shadow: 0 5px 10px 0 rgba(0,21,47,0.50);
}
.video_partner_card_bg_rp,
.sportspecialist_card_bg_rp {
	background: #2f0e6d;
	color: #FFFFFF;
	text-align: left;
	box-shadow: 0 5px 10px 0 rgba(0,21,47,0.50);
}
.video_card_bg_rp .card-title,
.favorites_card_bg_rp .card-title,
.modal-title,
.video_partner_card_bg_rp .card-title,
.sportcenter_card_bg_rp .card-title,
.sportspecialist_card_bg_rp .card-title {
	font-family: 'montserrat-bold';
}
.sportcenter_card_bg_rp .card-title {
	margin-bottom: inherit;
}
.favorites_card_bg_rp .card-title,
.favorites_card_bg_rp li,
.video_partner_card_bg_rp .card-title,
.video_card_bg_rp .card-title {
	width: 80%;
}
.video_card_bg_rp .card_image_rp,
.video_partner_card_bg_rp .card_image_rp,
.favorites_card_bg_rp .card_image_rp,
.sportcenter_card_bg_rp .card_image_rp {
	background-color: rgba(20, 46, 125, 0.2);
	background-repeat:no-repeat;
	background-position: 50%;
	overflow: hidden; /* Per contenere immagine card */
}
.video_partner_card_bg_rp .card_image_rp,
.video_card_bg_rp .card_image_rp {
	background-image: url(../images/video_player_icon.svg);
}
.video_card_bg_rp .card_image_pl {
	background-image: url(../images/playlist_icon_W.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-color: rgba(20, 46, 125, 0.2);
	background-size: 50%;
}
.video_partner_card_bg_rp .card_image_pl {
	background-image: url(../images/playlist_icon_W.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-color: #2f0e6d;
	background-size: 50%;
}
.video_card_bg_rp .video_card_image {
	background-color: rgba(20, 46, 125, 0.2);
	background-image: url(../images/default_field_image.png);
	background-size: 100%;
}
.video_partner_card_bg_rp .video_card_image {
	background-color: #2f0e6d;
	background-image: url(../images/default_field_image.png);
	background-size: 100%;
}
.favorites_card_bg_rp .card_image_rp {
	background-image: url(../images/favoritest_white_icon.svg);
	background-size: 30%;
}
.sportcenter_card_bg_rp .card_image_rp,
.sportspecialist_card_bg_rp .card_image_rp {
	background-image: url(../images/default_field_image.png);
	background-size: 100%;
}
.sportspecialist_card_bg_rp .card_image_sportspecialist_rp {
	background-size: cover;
	background-position: center;
}
.sportspecialist_card_bg_rp .card_image_sportspecialist_rp {
	overflow: hidden;
}
.sportspecialist_card_bg_rp .card_image_sportspecialist_rp img {
	position: relative;
	top: 12%;
	transform: translateY(-12%);
}
.event_card_bg_rp .card_image_rp {
	background-image: url(../images/default_special_event.png);
	background-size: 100%;
}
.favorites_card_bg_rp .card_image_favorites_rp,
.sportcenter_card_bg_rp .card_image_sportcenter_rp {
	background-size: cover;
	background-position: center;
}
.video_card_bg_rp li,
.video_partner_card_bg_rp li,
.favorites_card_bg_rp li,
.sportcenter_card_bg_rp li {
	color:#C9CED4;
	font-family: 'montserrat-regular';
}
.favorites_card_heart_btn {
	position: absolute;
	right: 10px;
	width: 30px;
	height: 30px;
	background-image: url(../images/favorites_heart_icon.svg);
	background-repeat: no-repeat;
	cursor: pointer;
}
.detail_head_rp .favorites_card_heart_btn {
	display: inline-block;
}
.favorites_card_heart_btn_active {
	position: absolute;
	right: 10px;
	width: 30px;
	height: 30px;
	display: inline-block;
	background-image: url(../images/favorites_heart_icon_active.svg);
	background-repeat: no-repeat;
	cursor: pointer;
}
.favorites_card_heart_btn:hover,
.favorites_card_heart_btn_active:hover {
	opacity:0.8;
}
.note_card_rp {
	color:#C9CED4;
	font-size: 10px;
	font-family: Arial;
}
.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.videoWrapper .preload {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	/*background-color: #AE003A;*/
	display: flex;
}
.videoWrapper .preload:hover  {
	cursor: pointer;
}
.videoWrapper .preload .play_arrow {
	width: 20%;
}

.videoWrapper .preload .logo-replayer {
	width: 10%;
	margin-left: 2%;
	margin-top: 2%;
}

.videoWrapper .preload .logo-partner {
	width: 15%;
	margin-right: 2%;
	margin-top: 2%;
}

.videoWrapper .preload .video-description {
	width: 100%;
	height: 20%;
	margin-bottom: 5%;
	background-color: white;
	color: #0c213d;
	text-align: center;
	opacity: 0.5;
	display: flex;
	font-family: 'montserrat-bold';
}

.empty_slot.video_card_bg_rp,
.empty_slot.video_partner_card_bg_rp,
.empty_slot.favorites_card_bg_rp,
.empty_slot.sportcenter_card_bg_rp {
	border: 1px solid rgba(158,158,158,0.30);
	background: #F5F5F5;
	color: #00152F;
	box-shadow: inset 0 1px 10px 10px rgba(158,158,158,0.30);
}
.empty_slot.favorites_card_bg_rp,
.empty_slot.sportcenter_card_bg_rp {
	overflow: hidden;
}
.empty_slot.video_card_bg_rp li,
.empty_slot.video_card_bg_rp .note_card_rp,
.empty_slot.video_partner_card_bg_rp li,
.empty_slot.video_partner_card_bg_rp .note_card_rp,
.empty_slot.favorites_card_bg_rp li,
.empty_slot.sportcenter_card_bg_rp li {
	color: #00152F !important;
}
.empty_slot .btn_red_rp {
	background: #00D6D2;
}
.empty_slot .btn_red_rp:hover {
	background: #00c1bd;
}

/**/
.reservation_bg_rp {
	background-image: linear-gradient(-45deg, #142E7D 47%, #00D6D2 100%);
	color: #FFFFFF;
	text-align: left;
	box-shadow: 0 5px 10px 0 rgba(0,21,47,0.50);
}
.reservation_bg_rp .card-title {
	font-family: 'montserrat-bold';
}
.reservation_bg_rp .card_image_rp {
	background-image: url(../images/man_mobile_phone.jpg);
	background-repeat:no-repeat;
	background-position: center;
	background-size: cover;
	min-height:100px;
}
.empty_slot.reservation_bg_rp .card_image_rp {
	background-image: url(../images/man_mobile_phone.jpg);
	opacity: 0.4;
}
.empty_slot.reservation_bg_rp {
	border: 1px solid rgba(158,158,158,0.30);
	background: #F5F5F5 !important;
	color: #00152F;
	box-shadow: inset 0 1px 10px 10px rgba(158,158,158,0.30);
	overflow: hidden;
}
@media (min-width: 575.98px) {
	.reservation_bg_rp .card_image_rp {
		height:auto;
	}
}
.reservation_bg_rp .card_image_overlay_rp {
	background: linear-gradient(45deg, rgba(20, 46, 125, 0.8) 0%, rgba(107, 26, 103, 0.8) 71%, rgba(221, 0, 74, 0.8) 100%);
	height: 100%;
	width: 100%;
	min-height:100px;
}
.empty_slot.reservation_bg_rp .card_image_overlay_rp {
	background: linear-gradient(45deg, rgba(20, 46, 125, 0.8) 0%, rgba(107, 26, 103, 0.8) 71%, rgba(221, 0, 74, 0.8) 100%);
	height: 100%;
	width: 100%;
	min-height:100px;
}
.reservation_bg_rp .card_image_icon_overlay_rp {
	background-image: url(../images/calendar_icon.svg);
	background-size: 15%;
	height: 100%;
	min-height:100px;
	width: 100%;
	background-position: center;
	background-repeat: no-repeat;
}
@media (min-width: 575.98px) {
	.reservation_bg_rp .card_image_icon_overlay_rp {
		background-size: 40%;
	}
}
.reservation_bg_rp li {
	color:#C9CED4;
	font-family: 'montserrat-regular';
}
.empty_slot.reservation_bg_rp li {
	color: #00152F !important;
}
.reservation_bg_rp .reservation_buttons_rp {
	text-align: center;
}
@media (min-width: 575.98px) {
	.reservation_bg_rp .reservation_buttons_rp {
		text-align: left;
	}
}
.list_location_pointer {
	margin-left: 14px;
	position: relative;
}
.list_location_pointer:before {
	content: " ";
	background-size: cover;
	background-image: url("../images/location_pointer_icon.svg");
	width: 20px;
	height: 30px;
	position: absolute;
	left: -20px;
}
ul.card_facilities_list_icons {
	list-style-type: none;
	margin: 0;
	padding: 0;
}
li.card_facilities_list_icons_item {
	float: left;
	display: block;
	text-decoration: none;
	width: auto;
	margin-right: 8px;
}
li.card_facilities_list_icons_item img {
	width: 20px;
	height: 20px;
}
.list_user_icon_private_white:before {
	content: " ";
	background-size: cover;
	background-image: url("../images/user_icon_private_white.svg");
	background-repeat:no-repeat;
	background-size: 100% auto;
	width: 15px;
	height: 20px;
	position: absolute;
	left: 2px;
	margin-left: 20px;
	margin-top: 2px;
}
li.list_user_icon_private_white {
	margin-left: 24px;
}
.list_user_icon_public_white:before {
	content: " ";
	background-size: cover;
	background-image: url("../images/user_icon_public_white.svg");
	background-repeat:no-repeat;
	background-size: 100% auto;
	width: 20px;
	height: 20px;
	position: absolute;
	left: 0px;
	margin-left: 20px;
	margin-top: 2px;
}
li.list_user_icon_public_white {
	margin-left: 24px;
}
.list_user_icon_replayer_white:before {
	content: " ";
	background-size: cover;
	background-image: url("../images/user_icon_replayer_white.svg");
	background-repeat:no-repeat;
	background-size: 100% auto;
	width: 20px;
	height: 20px;
	position: absolute;
	left: 0px;
	margin-left: 20px;
	margin-top: 2px;
}
li.list_user_icon_replayer_white {
	margin-left: 24px;
}
.favorites_card_badge {
	background-image: linear-gradient(45deg, #142E7D 0%, #6B1A67 71%, #DD004A 100%);
	padding: 10px;
	min-height: 75px;
}
.favorites_card_badge_icon {
	width: 60px;
	height: auto;
}
.favorites_card_badge a.btn_red_rp img {
	width: 60px;
	height: auto;
}
.favorites_card_badge p {
	padding-top: 10px;
	padding-right: 10px;
	padding-left: 20px;
	margin-bottom: 0;
	font-family: 'montserrat-regular';
	font-size: 0.8em;
}
/* Video archive */
ul.card_video_archive_list_icons {
	list-style-type: none;
	margin: 0;
	padding: 0 20px;
}
li.card_video_archive_list_icons_item {
	float: left;
	display: block;
	text-decoration: none;
	width: auto;
	margin-right: 8px;
	font-size: 12px;
}
li.card_video_archive_list_icons_item img {
	width: 40px;
	height: 40px;
}
li.card_video_archive_list_icons_item:hover {
	opacity:0.8;
	text-decoration: none;
}
li.card_video_archive_list_icons_item_user.card_video_archive_list_icons_item_user_padding img {
	padding-bottom: 12px;
}
.video_card_star_btn {
	position: absolute;
	right: 10px;
	width: 30px;
	height: 30px;
	background-image: url(../images/star_icon_empty.svg);
	background-repeat: no-repeat;
	cursor: pointer;
}
.video_card_star_btn_active {
	position: absolute;
	right: 10px;
	width: 30px;
	height: 30px;
	display: inline-block;
	background-image: url(../images/metadata_icon.svg);
	background-repeat: no-repeat;
	cursor: pointer;
}
.rtv_info_btn {
	position: absolute;
	width: 30px;
	height: 30px;
	display: inline-block;
	background-image: url(../images/metadata_icon.svg);
	background-repeat: no-repeat;
	cursor: pointer;
	margin-left: 5px;
}
.rtv_info_btn:hover,
.video_card_star_btn:hover,
.video_card_star_btn_active:hover {
	opacity:0.8;
}
/* Modal */
.modal-content {
	box-shadow: 0 0 10px 5px #00D6D2;
}
.modal-header {
	border-bottom: 1px solid #00D6D2;
}
.modal-footer {
	border-top: 1px solid #00D6D2;
}

.modal_rp .modal-content {
	box-shadow: 0 0 10px 5px #00D6D2;
}
.modal_rp .modal-header {
	border-bottom: 1px solid #00D6D2;
}
.modal_rp .modal-footer {
	border-top: 1px solid #00D6D2;
}
.modal_rp .rc-slider-step {
	position: absolute;
	width: 100%;
	height: auto;
	background: transparent;
}
.modal_rp .close span {
	color: #DD004A;
}
.modal_rp .close  span:hover {
	color: #AE003A;
}
.modal_rp .rc-slider-rail, .rc-slider-track {
	position: relative;
	height: 4px;
	border-radius: 6px;
}
.modal_rp .rc-slider-rail {
	width: 100%;
	background-color: #e9e9e9;
}
.modal_rp .rc-slider, .rc-slider * {
	box-sizing: border-box;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}
.modal_rp .rc-slider-handle {
	position: absolute;
	width: 14px;
	height: 14px;
	cursor: pointer;
	cursor: -webkit-grab;
	margin-top: -9px;
	cursor: grab;
	border-radius: 50%;
	border: 2px solid #00D6D2;
	background-color: #00D6D2;
	touch-action: pan-x;
}
.modal_rp .rc-slider-dot {
	position: absolute;
	bottom: -2px;
	margin-left: -4px;
	width: 8px;
	height: 8px;
	border: 2px solid #e9e9e9;
	background-color: #fff;
	cursor: pointer;
	border-radius: 50%;
	vertical-align: middle;
}
.modal_rp .rc-slider-mark {
	position: relative;
	top: initial;
	margin-top: 10px;
	margin-bottom: 10px;
	left: 0;
	width: 100%;
	font-size: 12px;
}
.modal_rp input.form-control {
	border: solid 1px #fff;
}
.modal_rp .share_radio_rp .input-group-text {
	width: 40px;
	background-color: #00D6D2;
}
.modal_rp .share_radio_rp .form-check-input {
	margin-left: auto;
}
.modal_rp .share_radio_rp.utente_privato_radio_rp label.form-control img {
	width: 24px;
	height:24px;
	padding-right: 8px;
}
.modal_rp .share_radio_rp.utente_replayer_radio_rp label.form-control img {
	width: 30px;
	height:30px;
	padding-right: 8px;
}
.modal_rp .share_radio_rp.utente_pubblico_radio_rp label.form-control img {
	width: 30px;
	height:30px;
	padding-right: 8px;
}
/* Sport Center Detail */
.detail_head_rp {
	background: #00152F;
	padding: 20px;
}
.detail_head_rp li.list-inline-item img {
	width: 30px;
	height: 30px;
	margin-right:6px;
}
.detail_head_rp li.list-inline-item {
	margin-right: 0rem;
}
.detail_head_rp hr {
	border-color: #fff;
}
.detail_head_rp .favorites_card_heart_btn {
	right: 30px;
}
.detail_head_rp .memberhip_rp .btn {
	border-radius: 20px;
}
.memberhip_rp_btn_icon {
	width: 20px;
	margin-top: -3px;
}
.memberhip_rp .btn_red_outline_rp:hover {
	background: none;
	border: solid 1px #DD004A;
	color: #ffffff;
}
.sportcenter_rp_title_icon {
	width: 50px;
	padding-right: 10px;
}
.memberhip_rp .btn.disabled {
	pointer-events: inherit !important;
}
.sportcenter_replayertv_section .replayer_tv_icon {
	max-width: 140px;
	padding:20px 10px;
}
@media (min-width: 575.98px) {
	.sportcenter_replayertv_section .replayer_tv_icon {
		max-width: 140px;
		padding:0px 10px;
	}
}
.sportcenter_gallery_section .sportcenter_social_icon {
	width: 40px;
}
.sportcenter_gallery_section .sportcenter_social_icon:hover {
	opacity: 0.8;
}
.video_store_head .video_store_head_filter_button {
	width: 100%;
	margin-bottom: 10px;
}
@media (min-width: 575.98px) {
	.video_store_head .video_store_head_filter_button {
		width: auto;
		margin-bottom: 0px;
	}
}
.video_store_card_player_icon {
	position: absolute;
	right: 10px;
	width: 30px;
	height: 30px;
	display: inline-block;
	background-image: url(../images/user_icon_private_red.svg);
	background-repeat: no-repeat;
	cursor: pointer;
}
.video_store_card_club_icon {
	position: absolute;
	right: 10px;
	width: 30px;
	height: 30px;
	display: inline-block;
	background-image: url(../images/club_icon_red.svg);
	background-repeat: no-repeat;
	cursor: pointer;
}
.video_store_card_event_icon {
	position: absolute;
	right: 10px;
	width: 30px;
	height: 30px;
	display: inline-block;
	background-image: url(../images/special_event.svg);
	background-repeat: no-repeat;
	cursor: pointer;
}
/* Video store */
.video_store_bg_rp {
	color: #fff;
	text-align: left;
	border: 1px solid #fff;
	background: #00D6D2;
	background-image: linear-gradient(-45deg, #142E7D 47%, #00D6D2 100%);
	border-radius: 6px;
	box-shadow: 0 5px 10px 0 rgba(0,21,47,0.50);
}
.video_store_bg_rp_specialist {
	color: #fff;
	text-align: left;
	border: 1px solid #fff;
	background: #be04b2;
	background-image: linear-gradient(-45deg, #2f0e6d 47%, #be04b2 100%);
	border-radius: 6px;
	box-shadow: 0 5px 10px 0 rgba(0,21,47,0.50);
}
.video_store_bg_rp_special_event {
	color: #fff;
	text-align: left;
	border: 1px solid #fff;
	background: #6B1A67;
	background-image: linear-gradient(-45deg, #DD004A 47%, #6B1A67 100%);
	border-radius: 6px;
	box-shadow: 0 5px 10px 0 rgba(0,21,47,0.50);
}
.video_store_bg_rp .card-title,
.video_store_bg_rp_specialist .card-title,
.video_store_bg_rp_special_event .card-title {
	font-family: 'montserrat-bold';
}
a.video_store_bg_rp h5.card-title,
a.video_store_bg_rp_specialist h5.card-title,
a.video_store_bg_rp_special_event h5.card-title {
	color: #fff;
}
.video_store_bg_rp:hover {
	text-decoration: none;
	background-image: linear-gradient(-45deg, #142E7D 17%, #00D6D2 100%);
	box-shadow: none;
	cursor: pointer;
}
.video_store_bg_rp_specialist:hover {
	text-decoration: none;
	background-image: linear-gradient(-45deg, #2f0e6d 17%, #be04b2 100%);
	box-shadow: none;
	cursor: pointer;
}
.video_store_bg_rp_special_event:hover {
	text-decoration: none;
	background-image: linear-gradient(-45deg, #DD004A 17%, #6B1A67 100%);
	box-shadow: none;
	cursor: pointer;
}
.video_store_logo_bg_rp {
	border: 1px solid rgba(158,158,158,0.30);
	border-radius: 0.25rem 0.25rem 0 0;
	background: #F5F5F5;
	color: #00152F;
	box-shadow: inset 0 1px 10px 10px rgba(158,158,158,0.30);
}
@media (min-width: 575.98px) {
	.video_store_logo_bg_rp {
		border-radius: 0.25rem 0 0 0.25rem;
	}
}
.video_store_logo_bg_rp img {
	max-height: 140px;
	padding: 10px;
}
.video_store_nologo_overlay_rp {
	background-image: url(../images/club_icon_grey.svg);
	background-size: 20%;
	height: 140px;
	width: 100%;
	background-position: center;
	background-repeat: no-repeat;
}
@media (min-width: 575.98px) {
	.video_store_nologo_overlay_rp {
		background-size: 50%;
		height: 100px;
	}
}
.video_store_image_rp {
	background-size: cover;
	background-position: 50%;
	overflow: hidden;
	border-radius: 0.25rem 0.25rem 0 0;
}
/* Replayer TV sport center */
.replayertv_sportcenter_hero_rp {
	background-image: url(../images/sportcenter_hero_bg.jpg);
	transform: translate3d(0px, 80px, 0px);
	background-position: 50% 50%;
	height: 500px;
	background-size: cover;
}
.replayertv_sportcenter_hero_overlay_rp {
	background: linear-gradient(45deg, rgba(20, 46, 125, 0.8) 60%, rgba(0, 214, 210, 0.8) 100%);
	height: 100%;
	width: 100%;
}
.replayertv_sportcenter_hero_overlay_rp_specialist {
	background: linear-gradient(45deg, rgba(103, 0, 72, 0.8) 60%, rgba(181, 0, 175, 0.876) 100%);
	height: 100%;
	width: 100%;
}
.replayertv_sportcenter_logo {
	border-radius: 50%;
	padding:10px;
	box-shadow: 0 5px 10px 0 rgba(0,21,47,0.50);
	height: 150px;
	width: 150px;
	overflow: hidden;
	background-color: #fff;
}
.replayertv_nologo_overlay_rp {
	background-image: url(../images/club_rtv_icon_grey.svg);
	background-size: 60%;
	height: 140px;
	width: 100%;
	background-position: center;
	background-repeat: no-repeat;
}
.video_partner_card_bg_rp.replayer_tv_card,
.video_card_bg_rp.replayer_tv_card {
	color:#08192d;
	background: #fff;
    color: #00152F;
    box-shadow: 0 10px 10px -5px #d1d1d1;
    border: none;
	text-align: left;
}
.video_partner_card_bg_rp.replayer_tv_card .card-title,
.video_card_bg_rp.replayer_tv_card .card-title {
	font-family: 'montserrat-bold';
	width: 100%;
}
.replayertv_card_academy_icon {
	position: absolute;
	right: 10px;
	width: 30px;
	height: 30px;
	display: inline-block;
	background-image: url(../images/coach_member_icon.svg);
	background-repeat: no-repeat;
	cursor: pointer;
}
p.replayertv_sportcenter_introtext {
	text-align: center;
}
p.replayertv_sportcenter_introtext a {
	text-decoration: underline;
	font-weight: bolder;
}

.video_store_dropdown {
	display: inline-block;
}

.video_store_dropdown .btn {
	background: none;
	border: solid 1px #DD004A;
	color: #ffffff;
}

/* Replayer sponsor */
.replayer_adv_sponsor_hero {
	background-image: url(../images/replayer_adv_sponsor_hero.jpg);
	transform: translate3d(0px, 80px, 0px);
	background-position: 50% 50%;
	height: 500px;
	background-size: cover;
}
.replayer_adv_sponsor_hero_overlay_rp {
	background: linear-gradient(45deg, rgba(20, 46, 125, 0.8) 60%, rgba(0, 214, 210, 0.8) 100%);
	height: 100%;
	width: 100%;
}

/* white favorites heart */
.white_favorites_card_heart_btn {
	position: absolute;
	right: 10px;
	width: 30px;
	height: 30px;
	background-image: url(../images/white_favorites_heart_icon.svg);
	background-repeat: no-repeat;
	cursor: pointer;
}
.detail_head_rp .white_favorites_card_heart_btn {
	display: inline-block;
}
.white_favorites_card_heart_btn_active {
	position: absolute;
	right: 10px;
	width: 30px;
	height: 30px;
	display: inline-block;
	background-image: url(../images/white_favorites_heart_icon_active.svg);
	background-repeat: no-repeat;
	cursor: pointer;
}
.white_favorites_card_heart_btn:hover,
.white_favorites_card_heart_btn_active:hover {
	opacity:0.8;
}
.detail_head_rp .white_favorites_card_heart_btn {
	right: 30px;
}

/* categories checkbox */
.categories_checkbox_btn {
	position: absolute;
	right: 10px;
	width: 30px;
	height: 30px;
	background-image: url(../images/check_box_outline_blank_white_24dp.svg);
	background-repeat: no-repeat;
	cursor: pointer;
}
.detail_head_rp .white_favorites_card_heart_btn {
	display: inline-block;
}
.categories_checkbox_btn_active {
	position: absolute;
	right: 10px;
	width: 30px;
	height: 30px;
	display: inline-block;
	background-image: url(../images/check_box_red_24dp.svg);
	background-repeat: no-repeat;
	cursor: pointer;
}
.categories_checkbox_btn:hover,
.categories_checkbox_btn_active:hover {
	opacity:0.8;
}
.detail_head_rp .categories_checkbox_btn {
	right: 30px;
}

.custom-switch .custom-control-label::before {
	background-color: red;
	border-color: red;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
	background-color: green;
	border-color: green;
}

.custom-switch .custom-control-label::after{
	background-color: white;
}

.custom-switch .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
	border-color: #ebebeb;
  }

  .custom-datepicker {
    text-align: center;
}