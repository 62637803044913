.rbc-calendar {
  margin-top: 100px;
  width: 80%;
}

.rbc-today {
  background-color: #3b6e96;
}

.presentation-container {
  padding-right: 0px;
  padding-left: 0px;
}

.presentation-image {
  opacity: 0.5;
  width: 100%;
}

.rbc-time-gutter {
  background-color: inherit !important;
}

.rbc-time-header-gutter {
  background-color: inherit !important;
}

.rbc-today {
  background-color: inherit !important;
}

.rbc-day-slot .rbc-time-slot {
  border-top: 1px solid #3b6e96;
}

.rbc-slot-selection {
  background-color: rgba(255, 255, 255, 0.5);
}

.login-container {
  border: 1px solid aliceblue;
  max-width: 800px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.facilities .list-group-item {
  padding-top: 0rem;
  padding-right: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 0rem;
}

.rbc-timeslot-group {
  min-height: 80px;
}

.rbc-current-time-indicator {
  background-color: #DD004A;
}

.rbc-toolbar button {
  color: #DD004A;
}

.modal-content {
  background: #00152F;
  box-shadow: 0 0 10px 5px #00D6D2;
}

.player-profile-tabs {
  border-bottom: 1px solid #DD004A !important;
}

.player-profile-tab {
  padding: 0.5em !important;
}

.player-profile-tab.active {
  padding: 0.5em !important;
  border-color: #DD004A #DD004A transparent !important;
}

.myvideo-tabs {
  border-bottom: 1px solid #DD004A !important;
}

.myvideo-tab {
  padding: 0.5em !important;
}

.myvideo-tab.active {
  padding: 0.5em !important;
  border-color: #DD004A #DD004A transparent !important;
  background-color: #DD004A !important;
}

.videostore-tabs {
  border-bottom: 1px solid #DD004A !important;
}

.videostore-tab {
  padding: 0.5em !important;
  background: #00152f50 !important;
  margin: 0px !important;
}

.videostore-tab.active {
  padding: 0.5em !important;
  border-color: #DD004A #DD004A transparent !important;
  background-color: #DD004A !important;
}

.admin-show-partner-tabs {
  border-bottom: 1px solid #DD004A !important;
}

.admin-show-partner-tab {
  padding: 0.5em !important;
  color: darkblue !important;
}

.admin-show-partner-tab.active {
  padding: 0.5em !important;
  border-color: #DD004A #DD004A transparent !important;
  background-color: #DD004A !important;
  color: white !important;
}

.rbc-event {
  width: 100% !important;
  left: 0 !important;
}

.color-red {
  color: red !important;
}

.color-green {
  color: green !important;
}

.wrapper {
  justify-content: center;
  display: flex;
  align-items: center;
}

.cta {
    display: flex;
    padding: 10px 45px;
    text-decoration: none;
    font-family: "Montserrat-bold", sans-serif;
    font-size: 40px;
    color: #DD004A;
    background: #00152F;
    transition: 1s;
    box-shadow: 6px 6px 0 #DD004A;
    transform: skewX(-15deg);
    font-style: italic;

}

.cta:focus {
   outline: none; 
}

.cta:hover {
    transition: 0.5s;
    box-shadow: 10px 10px 0 white;
    color: white;
    text-decoration: none;
}

.cta span:nth-child(2) {
    transition: 0.5s;
    margin-right: 45px;
}

.live-now-span {
  transform: skewX(15deg) 
}

.live-now-span:nth-child(2) {
  width: 20px;
  margin-left: 30px;
  position: relative;
  top: 12%;
}
  
/**************SVG****************/

path.one {
    transition: 0.4s;
    transform: translateX(0%);
    animation: color_anim 1s infinite 0.6s;
}

path.two {
    transition: 0.5s;
    transform: translateX(0%);
    animation: color_anim 1s infinite 0.4s;
}

path.three {
  animation: color_anim 1s infinite 0.2s;
}

/* SVG animations */

@keyframes color_anim {
    0% {
        fill: #DD004A;
    }
    50% {
        fill: white;
    }
    100% {
        fill: #DD004A;
    }
}

@media (max-width: 912px) {
  .live-now-mobile {
    position: relative;
    top: 60px;
    justify-content: center;
  }
  .player-home-new-videos {
    margin-top: 80px; 
  }
  .cta{
    font-size: 32px;
  }
}

ul.react-paginate {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style-type: none;
  padding: 0 5rem;
}

ul.react-paginate li a {
  padding: 0.1rem 1rem;
  border: white 1px solid;
  cursor: pointer;
}
ul.react-paginate li a:hover {
  background-color: grey;
}
ul.react-paginate li.previous a,
ul.react-paginate li.next a,
ul.react-paginate li.break a {
  border-color: white;
  cursor: pointer;
}
ul.react-paginate li.previous a:hover,
ul.react-paginate li.next a:hover {
  background-color: #DD004A;
}
ul.react-paginate li.selected a {
  background-color: #DD004A;
  border-color: white;
  color: white;
  min-width: 32px;
}
ul.react-paginate li.disabled a {
  color: grey;
}
ul.react-paginate li.disable,
ul.react-paginate li.disabled a {
  cursor: pointer;
}